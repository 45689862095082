/** @format */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://api.fontshare.com/v2/css?f[]=cabinet-grotesk@1,800,500,700,400,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.App {
  text-align: center;
}

.link {
  text-decoration: underline;
  color: blue;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.left-nav {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: inherit;
  background-color: white;
  text-align: right;
}

.right-nav {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3;
  height: inherit;
  /* vertical-align: middle; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.mainFont{
  font-size: .7rem;
}

/* h1, h2, h3, h4, h5, h6 {
  font-family: "Cabinet Grotesk", -apple-system, system-ui, sans-serif;
} */

body {
  font-family: "Inter", -apple-system, system-ui, sans-serif;
  background: white;
  margin-top: 80px;
}

* {
  font-weight: 300 !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 200 !important;
}

span {
  font-weight: 600 !important;
}

@layer components {
  .form-input {
    @apply w-full px-3 py-2 rounded-lg border border-gray-300 focus:ring-1 focus:ring-primary/50 focus:border-primary outline-none transition duration-150 ease-in-out text-sm;
  }
  .form-select {
     @apply w-full px-3 py-2 rounded-lg border border-gray-300 focus:ring-1 focus:ring-primary/50 focus:border-primary outline-none transition duration-150 ease-in-out text-sm bg-white appearance-none;
  }
  .btn {
    @apply px-5 py-2.5 rounded-lg font-semibold text-center shadow-sm transition-all duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed;
  }
  .btn-primary {
     @apply bg-primary text-white hover:bg-primary/90;
  }
  .btn-secondary { @apply bg-gray-600 text-white hover:bg-gray-700; }
  .btn-primary-outline { @apply bg-white text-primary border-2 border-primary hover:bg-primary/10; }
  .btn-secondary-outline { @apply bg-white text-gray-700 border border-gray-300 hover:border-gray-400 hover:bg-gray-50; }

}

.markdown > * {
  all: revert;
}
/* Custom Slick Dots */
.slick-dots li button .slick-dot-custom {
  /* Styles for inactive dots are applied via Tailwind above */
  transition: background-color 0.2s ease-in-out;
}

/* Optional: Hide default slick arrows if using custom */
.slick-prev::before,
.slick-next::before {
  color: rgb(190 32 38 / 1) !important;
}